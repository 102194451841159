.signature {
  &__display {
    font-family: 'Great Vibes', cursive;
    font-size: 3rem;
  }

  &__options {
    margin: 0 -5px;
    button {
      margin: 0 5px;
    }
  }
}
