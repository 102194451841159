@import "~slick-carousel/slick/slick.scss";

.carousel {
  position: relative;

  .slick-slide {
    height: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7413340336134453) 0%, rgba(255, 255, 255, 0) 50%);
    z-index: 1;
  }
}