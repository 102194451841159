.vertical-tabs {
  $tab-border-color: $base-border-color;
  $tab-border: 1px solid $tab-border-color;
  $tab-border-radius: $base-border-radius;
  $tab-content-background: $lightest-gray;
  $tab-active-color: $tab-content-background;
  $tab-inactive-color: $base-background-color;
  $vertical-tabs-height: 18.75em;
  $vertical-tab-mode: $medium-screen-up;

  display: flex;
  flex-direction: column;
  border: $tab-border;

  @include grid-media($medium-screen-up) {
    flex-direction: row;
  }

  &__tabs {
    display: none;

    @include grid-media($vertical-tab-mode) {
      background-color: $tab-inactive-color;
      flex-direction: column;
      display: flex;
      width: 25%;
      min-height: $vertical-tabs-height;
      max-width: $max-width;
    }
  }

  &__tab {
    border-bottom: $tab-border;
    display: block;
    font-weight: bold;
    margin-right: -1px;
    padding: ($base-spacing / 2) ($gutter-width / 2);

    &,
    &:focus,
    &:active {
      color: $base-font-color;
    }

    &--active {
      background-color: $tab-content-background;
      margin-right: -1px;
    }
  }

  &__accordian-title {
    background-color: $lighter-gray;
    border-top: $tab-border;
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding: $base-spacing / 2 $gutter-width / 2;
    margin: 0px -#{$gutter-width/2};

    &,
    &:focus,
    &:active {
      color: inherit;
    }

    &:first-child {
      border-top: 0;
    }

    &--active {
      background: $dark-blue;
      border-bottom: 0;
      &,
      &:focus,
      &:active {
        color: white;
      }
    }

    @include grid-media($vertical-tab-mode) {
      display: none;
    }
  }

  &__tab-content {
    border: 0;
    display: none;
    padding-top: $gutter-width;

    @include grid-media($vertical-tab-mode) {
      padding: 0;
    }
    &--active {
      display: block;
    }
  }
  &__content {
    background-color: $tab-content-background;
    padding: 0 $gutter-width/2;

    @include grid-media($vertical-tab-mode) {
      // padding: 2em;
      padding: $gutter-width;
      width: 75%;
    }

    h2.flex-title {
      font-size: $font-size-h3;
    }
  }
}
