@import 'variables';

@import '~normalize-scss';
@include normalize();
@import '~bourbon/app/assets/stylesheets/bourbon';
@import '~bourbon-neat/core/neat';
@import '~react-datepicker/dist/react-datepicker.css';

@import 'mixins/antialias';
@import 'mixins/aspect-ratio';
@import 'mixins/center-vertically';
@import 'mixins/dot-loader';
@import 'mixins/em';
@import 'mixins/fill-background';
@import 'mixins/light-on-dark';
@import 'mixins/narrow-container';
@import 'mixins/site-padding';
@import 'mixins/sr-only';

@import 'base/base';

@import 'blocks/alerts';
@import 'blocks/footer';
@import 'blocks/form-container';
@import 'blocks/header';
@import 'blocks/nav';
@import 'blocks/subnav';
@import 'blocks/table';
@import 'blocks/notice';

@import 'forms';
@import 'flex-boxes';
@import 'tables';
@import 'buttons';
@import 'flashes';
@import 'cards';
@import 'src/components/carousel/carousel.scss';
@import 'src/components/form/form.scss';
@import 'src/components/loading/loading.scss';
@import 'src/components/signature/signature.scss';
@import 'src/components/dashboard/dashboard.scss';
@import 'src/components/initial/initial.scss';
@import 'src/components/modal/modal.scss';
