// $base-spacing: 1.5em !default;
// $alert-color: #fff6bf !default;
// $error-color: #fbe3e4 !default;
// $notice-color: #e5edf8 !default;
// $success-color: #e6efc2 !default;

@mixin flash($color) {
  background-color: $color;
  color: darken($color, 60%);
  display: block;
  font-weight: 600;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2;
  position: fixed;
  top: 20px;
  right: 20px;

  a {
    color: darken($color, 70%);
    text-decoration: underline;

    &:focus,
    &:hover {
      color: darken($color, 90%);
    }
  }

  > p:last-child {
    margin-bottom: 0;
  }
}

.flash-alert,
.alert-warning {
  @include flash($alert-color);
}

.flash-error,
.alert-danger {
  @include flash($error-color);
}

.flash-notice,
.alert-info {
  @include flash($notice-color);
}

.flash-success,
.alert-success {
  @include flash($success-color);
}
