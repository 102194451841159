html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

.container {
  margin-left: auto; margin-right: auto; max-width: $max-width;
  @include grid-container;
  @include site-padding;
}
.container-wide {
  @extend .container;
  max-width: 1380px;
}
.container-thin {
  @extend .container;
  max-width: 840px;
}

.desktop_only {
  display:none;
}
@include grid-media($medium-screen-up) {
  .mobile_only { display:none; }
}
@include grid-media($large-screen-up) {
  .mobile_and_tablet_only { display:none; }
  .desktop_only { display:initial; }
}

.nav_open { overflow:hidden; }

a[data-ember-action] {
  cursor: pointer;
}

section {
  @include grid-container;
  padding-top:1.5em;
  aside { @include grid-column(3); }
  article { @include grid-column(9); }
}

.row { margin-bottom:30px; }

.centered { text-align:center; }

div.inline { display:inline-block; }

.hide-mobile {
  @media screen and (max-width: $medium-screen - 1) {
    display:none;
  }
}
.hide-desktop {
  display:none;
  @media screen and (max-width: $medium-screen - 1) {
    display:inline;
  }
}

.two-column {
  columns: 2;
}

.border {
  border: 1px solid black
}