// @mixin button($background-color, $color, $border-color:$color) {
//   border:1px solid;
//   background:$background-color; border-color:$border-color; color:$color;
//   &:hover,
//   &:focus { background-color:darken($background-color, 20%); color:#fff; }
// }

// #{$all-button-inputs},
// button {
//   @include button(lighten($base-border-color, 10%), #333, darken($base-border-color, 20%));

//   &.danger {
//     @include button(darken($error-color, 10%), darken($error-color, 60%));
//   }

//   &.success {
//     @include button(darken($success-color, 10%), darken($success-color, 60%));
//   }

//   &.info {
//     @include button(darken($notice-color, 10%), darken($notice-color, 60%));
//   }
//   &.sm { padding:0.25em 0.5em; }
// }

.btn.sm {
  height: 26px;
}

.btn {
  &--link {
    color: $light-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.button-group {
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 20px;
  }
}

.progress-buttons {
  display: flex;
  flex-direction: column;
  background: linear-gradient(white 0%, white 48%, black 49%, black 50%, white 51%, white 100%);
  margin-top: 25px;

  @include grid-media($medium-screen-up) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__legend {
    display: flex;
    justify-content: center;
  }

  &__legend-item {
    &:last-child {
      margin-left: 15px;
    }

    &.complete .icon {
      color: #b4cf49;
    }

    &.error .icon {
      color: #e85d63;
    }
  }
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &--centered {
    justify-content: center;
  }
}

.cancel-button {
  background-color: #6c757d;
}

.save-button,
.save-button:hover {
  background-color: white;
  border: 1px solid $light-blue;
  color: $light-blue;
}

.page-button:disabled {
  opacity: 1;
}

.page-button {
  padding: 18px;
  position: relative;
  white-space: normal;
  width: 100%;
  height: 100%;
  background: none;
  color: black;
  font-weight: 400;
  background-color: white;

  @include grid-media($medium-screen-up) {
    padding: 10px;
  }

  &.complete {
    border: 1px solid #b4cf49;
  }
  &.incomplete {
    border: 1px solid #6c757d;
  }
  &.active {
    border: 1px solid #00ccff;
  }
  &.error {
    border: 1px solid #e85d63;
  }

  &:hover {
    background-color: white;
    color: black;
  }

  &__number {
    @include grid-media($medium-screen-up) {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: white;
    padding: 1px 5px;
    border-radius: 50%;

    &.complete {
      color: #b4cf49;
    }

    &.error {
      color: #e85d63;
    }
  }

  &__wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    @include grid-media($medium-screen-up) {
      width: 15%;
      margin-bottom: 0;
    }
  }
}
