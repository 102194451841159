.flex-boxes {
  $flex-box-border-color: $light-gray;
  $flex-box-border: 1px solid $flex-box-border-color;
  $flex-box-background: lighten($light-gray, 20%);
  $flex-box-gutter: 0.4em;
  $flex-box-width: 18em;
  $flex-box-color: transparentize($base-font-color, 0.3);
  $flex-box-top-colors: tomato, #72bfbf, #92b1e3, #e3d743, #ccc, #f6c05c;

  @include clearfix;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  clear: both;
  text-align: left;

  .flex-box {
    @include transition(all 0.2s ease-in-out);
    @include flex(2 2 25em);
    @include align-self(stretch);
    background: $flex-box-background;
    border-radius: $base-border-radius;
    display: block;
    margin: $flex-box-gutter;
    text-decoration: none;

    .content {
      padding: 2em 2em 3em 2em;
    }

    @for $i from 1 to 7 {
      // assign colors from $flex-box-top-colors list
      &:nth-child(#{$i}) {
        $color-from-list: nth($flex-box-top-colors, $i);
        border-top: 6px solid $color-from-list;

        &:focus,
        &:hover {
          // background-color: transparentize($color-from-list, 0.9);
        }
      }
    }

    img {
      display: block;
      height: 3em;
      margin-bottom: 2em;
      margin: auto;
      opacity: 0.4;
    }

    .flex-title {
      color: $flex-box-color;
      font-size: 1.2em;
      font-weight: 800;
      margin-bottom: 0.75em;
      margin-top: 0.75em;
    }

    .flex-action {
      float: right;
      margin-bottom: 10px;
    }

    p {
      color: transparentize($flex-box-color, 0.1);
      line-height: 1.5em;
      // margin: auto;
    }

    .flex-footer {
      text-align: right;
      padding: 1em 2em 1em 2em;
      border-top: 1px solid darken($flex-box-background, 10%);
    }
  }
  .flex-border {
    border-bottom: 6px solid #72bfbf;
    margin-bottom: 20px;
  }

  .flex-box-big {
    @include flex(1 1 40em);
  }
}
