$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;

$dark-gray: #333 !default;
$brand-primary: #C1D72E;

$base-font-color: $dark-gray !default;
$base-border-color: gainsboro !default;
$base-background-color: white !default;
$action-color: #477DCA !default;
$base-link-color: $action-color !default;

$base-border-radius: 3px !default;
$base-spacing: 1.5em !default;
