.main-nav {
  float: right;

  &__collapse {
    background:$dark-blue; width:100%;
    position:absolute; top:100%; left:0;
    transform: translateY(-120%);
  }
  &.open &__collapse {
    transform: translateY(0);
  }
  &__account {
    padding: 12.5px 0; background: rgba(0,0,0,0.1);
    // @include grid-media($medium-screen-up) { padding: 12.5px 0; }
    li {
      display: inline-block;
    }
    a {
      color: white; background: transparent; float: right; padding: 25px 20px;
      @include grid-media($large-screen-up) { float: none; padding: 0 10px;  }
    }
    .btn {
      padding:25px 20px; text-transform: none;
      background: transparent; color: #FFF; padding:25px 20px; display:inline-block; font-size:24px; font-weight:bold; text-transform:uppercase;
    }
  }
  &__items { font-size:24px; font-weight:bold; text-transform:uppercase; }
  &__link { color: #FFF; padding:25px 20px; display:block; }
  &__toggle {
    font-size:25px; line-height:25px; transition:all 0.5s linear;
    background: transparent;
    padding: 0;
    &:link,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      color: #FFF;
    }
  }
  @include grid-media($large-screen-up) {
    @include grid-column(6);
    float: right;
    width: 600px;
    &__collapse {
      background: transparent;
      float: right; text-align: right;
      position: static;
      transform: translateY(0);
    }
    &__account { text-align: right; background: transparent; }
    &__items {
      @include clearfix; display: inline-block;
      font-size:18px;
      border:1px solid rgba(255, 255, 255, 0.20);
    }
    &__item {
      float:left;
    }
    &__link {
      display:block; padding:5px 10px;
      &.active,
      &:hover,
      &:focus {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.22));
        background-color:#003F5E;
        color:inherit;
      }
    }
    &__account .btn {
      padding:5px 10px; font-size:18px; border: 1px solid rgba(255, 255, 255, 0.20);
      &.active,
      &:hover,
      &:focus {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.22));
        background-color:#003F5E;
        color:inherit;
      }
    }
  }
  @include grid-media($xl-screen-up) {
    width: 700px;
    &__link, &__account .btn { padding:5px 20px; }
  }
}
