// $alerts: (
//   "alert": #fff6bf,
//   "error": #fbe3e4,
//   "notice": #e5edf8,
//   "success": #e6efc2,
// );

.notice {
  display: block;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2;
  text-align: center;

  a:not(.btn) { text-decoration: underline; }

  > p:last-child {
    margin-bottom: 0;
  }

  @each $alert-type, $color in $alerts {
    &--#{$alert-type} {
      background-color: $color;
      color: shade($color, 60%);
      a:not(.btn) {
        color: shade($color, 70%);
        &:focus,
        &:hover {
          color: shade($color, 90%);
        }
      }
    }
  }

  &--outline {
    border: 2px dashed;
  }
}
