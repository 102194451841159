body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1 {
  font-size:$font-size-h1 * 2/3;
  margin: 0 0 $small-spacing/2;
  @include grid-media($large-screen-up) { font-size: $font-size-h1; }
}
h2 { font-size:$font-size-h2; }
h3 { font-size:$font-size-h3; }
h4 { font-size:$font-size-h4; }
small { font-size: $font-size-small; }

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }

  &.gray {
    color: #6C757D;
  }

  &.underlined {
    text-decoration: underline;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.fa-times-thin:before {
  content: '\00d7';
}
