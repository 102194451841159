form {
  .errors--base {
    color: darken($error-color, 60%);
    margin: 10px 0;
  }

  &__inline-field {
    color: red;
    label {
      display: none;
    }
  }
}
.input-group {
  display: table;
  width: 100%;
  .input {
    display: table-cell;
    &:not(:first-child) {
      padding-left: 10px;
    }
  }
}

.input {
  margin-bottom: $small-spacing;

  &.has-error {
    color: darken($error-color, 60%);
    position: relative;
    input {
      color: inherit;
    }
    .errors {
      font-size: 0.8em;
      text-align: right;
      margin-bottom: 10px;
    }
  }

  &--inline {
    display: inline-block;
  }
}

.inline-error {
  color: darken($error-color, 60%);
  margin-bottom: $small-spacing;
}

.out-of-country-fields {
  // background-color: $success-color;
}

.list-field {
  margin-bottom: 15px;

  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.75em;

    .input {
      width: 100%;
      margin-right: 20px;
      margin-bottom: 0;

      input {
        margin-bottom: 0;
      }
    }
  }
}

.option-list {
  th,
  td {
    &:not(:first-child) {
      text-align: center;
    }
  }
}
