$all-inputs: append($all-text-inputs, 'select, textarea', comma);

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  &.inline {
    .inline_fill {
      position: relative;
      width: auto;
      overflow: hidden;
      white-space: nowrap;
    }

    .inline_left {
      float: left;
    }

    .inline_right {
      float: right;
    }

    .inline_right input[type="submit"] {
      margin-left: 2px;
    }

    #{$all-inputs} {
      margin-bottom: 0;
    }
  }
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: 0.825em;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

.form {
  legend {
    background: $dark-blue;
    font-size: 1.125em;
    color: hsl(200, 100%, 94%);
    display: block;
    padding: 1em 1em;
    width: 100%;
  }

  &__group {
    padding-top: 1em;
    position: relative;
  }

  &__dropdown {
    margin-bottom: 3em;
  }

  &__label {
    position: absolute;
    left: 5px;
    top: 17px;
    z-index: 1;
    margin-left: -100%;
    color: $gray;
    opacity: 0;
    font-size: .8rem;
    text-transform: uppercase;
    padding: 0 3px;
    transition: top 0.3s ease, opacity 0.3s ease;
  }

  &__label--visible {
    margin-left: 0;
    top: 6px;
    background-color: #fff;
    opacity: 1;
    background-image: linear-gradient(to bottom, transparent 3%, transparent 50%, #fff 50%, #fff 100%);
    padding-right: 4px;
  }

  &__hint {
    font-style: italic;
  }

  &__inline-field {
    display: inline-block;
    margin: 0.5em 5px !important;

    label {
      display: none;
    }

    .errors {
      position: absolute;
      bottom: -16px;
      right: 0;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
}

// Checkbox/Radio Buttons
.radio,
.checkbox {
  position: relative;

  >label {
    position: relative;
    display: block;
    padding: 0.75em;
    padding-left: 1.75em;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 18px;
      height: 18px;
      border: $base-border;
      border-radius: 1em;
      transition: all 0.2s ease-in-out;
    }

    input:last-child {
      margin-bottom: 0;
    }
  }

  &.inline {
    label {
      display: inline-block;
    }
  }

  &.on>label:after {
    background-image: none;
    background-color: #fff;
    box-shadow: 0 0 0 6px $action-color inset;
    border-color: $action-color;
  }

  [type="radio"],
  [type="checkbox"] {
    @include sr-only;
  }
}

.radio {
  border: $base-border;

  >label {
    padding-left: 2.5em;

    &:after {
      left: .5625em;
    }
  }
}

.radio_group {
  .radio {
    border-bottom-width: 0;

    >label {
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom-width: 1px;
      margin-bottom: $small-spacing / 2;
    }

    .radio_panel {
      display: none;
      border-top: $base-border;
      background: $lighter-gray;
      padding: 1em;
    }

    &.on .radio_panel {
      display: block;
    }
  }

  @include grid-media($medium-screen-up) {
    &.inline {
      @include clearfix;

      .radio {
        border-bottom-width: 1px;
        display: inline-block;
        margin-right: -5px;

        &:last-child {
          margin-right: 0;
        }

        label {
          padding-left: 3.25em;
          padding-right: 3em;
        }
      }
    }

    .radio label #{$all-inputs} {
      font-size: 15px;
      padding-bottom: 0;
      padding-top: 0;
      display: inline-block;
      width: auto;
    }
  }
}

.checkbox {
  >label {
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 1.25em;
      left: 0.25em;
      background: 0 0;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      height: 6px;
      transform: scale(0) rotate(-45deg);
      width: 10px;
      z-index: 2;
      transition: all 0.2s ease-in-out 0.1s;
    }

    &:after {
      z-index: 1;
      border-radius: 0;
    }
  }

  &.on>label {
    &:before {
      transform: scale(1) rotate(-45deg);
    }

    &:after {
      box-shadow: 0 0 0 8px $action-color inset;
    }
  }
}

.select {
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 1.25em;
    top: 1.125em;
    margin: 0 3px;
    border-left: 2px solid $gray;
    border-bottom: 2px solid $gray;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
  }
}

// Labels inside text fields
.text {
  >label {
    font-size: 0.85714em;
    font-weight: normal;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 0.3em;
    padding: 0 0.93333em;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    opacity: 0;
  }

  >input {
    padding: 0.94em 0.8em;
    transition: all 0.2s ease-out;
  }

  &.show_label {
    padding-top: 1.5em;
    padding-bottom: 0.38em;
  }
}

// Sign-Up Form Page