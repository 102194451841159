// Taken from: http://codepen.io/msisto/pen/LntJe

// Spinner Variables
//********************************************************//
$color:      $brand-primary;
$size:       30px;
$speed:      0.75s;
$thickness:  8px; // Odd numbers created a "wobble" effect.


// Animation Keyframes
//********************************************************//
@mixin rotate-forever {
  0%    { @include transform(rotate(0deg)); }
  100%  { @include transform(rotate(360deg)); }
}
@-webkit-keyframes rotate-forever { @include rotate-forever; }
   @-moz-keyframes rotate-forever { @include rotate-forever; }
        @keyframes rotate-forever { @include rotate-forever; }


// The Loading Spinner™
//********************************************************//
.loading-container {
  position:relative;
  .loading {
    @include position(absolute, 50% 0 0 0);
    margin: -$size/2 0 0 0;
    text-align:center;
    .spinner {
      @include animation-duration($speed);
      @include animation-iteration-count(infinite);
      @include animation-name(rotate-forever);
      @include animation-timing-function(linear);
      @include size($size);
      border: $thickness solid $color;
      border-right-color: transparent;
      border-radius: 50%;
      display: inline-block;
    }
  }
}