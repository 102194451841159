$max-width: 1280px;

$gutter-width: 20px;

// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;
$xl-screen: 1200px;

$neat-grid: (columns: 4,
  gutter: $gutter-width);
$medium-screen-up: (columns: 8,
  gutter: $gutter-width,
  media: $medium-screen);
$large-screen-up: (columns: 12,
  gutter: $gutter-width,
  media: $large-screen);
$xl-screen-up: (columns: 12,
  gutter: $gutter-width,
  media: $xl-screen);

// Typography
$base-font-family: "Proxima-Nova",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$font-size-small: 14px;
$base-font-size: 16px;
$font-size-large: 20px;
$font-size-h1: 48px;
$font-size-h2: 38px;
$font-size-h3: 25px;
$font-size-h4: 20px;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Younglife colors
$lightest-gray: #F6F6F6;
$lighter-gray: #eaeaea;
$light-gray: #c2c3c3;
$gray: #646464;
$dark-gray: #323232;
$darker-gray: #282828;
$dark-blue: #003f5e;
$light-blue: #00ccff;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $light-blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow,
0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;