.card {
  $card-border-color: $base-border-color;
  $card-border: 1px solid $card-border-color;
  $card-background: white;
  $card-header-background: white;
  $button-border-color: darken($card-border-color, 10%);
  $card-margin: 1em;
  // $card-image-hover-opacity: 0.7;
  // $card-image-hover-color: #F8F2B4;

  @include transition(all 0.2s ease-in-out);
  border-radius: $base-border-radius;
  // border: $card-border;
  // box-shadow: 0 2px 4px darken($base-background-color, 10%);
  cursor: pointer;
  position: relative;
  width: 100%;
  margin-bottom: $base-spacing;

  .card-header {
    @include transition(all 0.2s ease-in-out);
    background-color: $card-header-background;
    margin-bottom: 5px;
    border-radius: $base-border-radius $base-border-radius 0 0;
    line-height: 1.5em;
    padding: ($base-spacing / 3) ($base-spacing / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .left {
      order: 1;
    }
    .right {
      order: 2;
    }

    button {
      padding: 0.25em 0.5em;
      margin-left: 5px;
      font-size: 14px;
    }
  }

  .card-copy {
    font-size: 0.9em;
    line-height: 1.5em;
    padding: ($base-spacing / 2) ($base-spacing / 2);
    background: $card-background;

    p {
      margin: 0 0 ($base-spacing / 2);
    }
  }

  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: $card-background;

    .card-header {
      background-color: $card-background;
    }
  }

  .card-footer {
    @include transition(all 0.2s ease-in-out);
    background-color: $card-header-background;
    margin-top: 5px;
    border-radius: 0 0 $base-border-radius $base-border-radius;
    font-weight: bold;
    line-height: 1.5em;
    padding: ($base-spacing / 3) ($base-spacing / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
