#footer {
  font-weight:bold;
  @include light-on-dark;
  margin-top: $gutter-width * 3;
  #main-footer,
  #second-footer { padding:50px 0; }
  #main-footer {
    background:$dark-gray; color:hsl(0, 0%, 84%); font-size:$font-size-large;
    .address { color:$gray; }
  }
  
  #second-footer {
    background:$darker-gray; color:$gray;
    .mobile_and_tablet_only p { margin-bottom:8px; }
    .mobile_and_tablet_only a { font-size:$font-size-large; }
  }

  a { color:inherit; }
  .facebook svg { background:#fff; color:$dark-gray; border-radius:15px; width:15px; text-indent:5px; font-size:13px; height:15px; line-height:21px; margin-right:5px; padding: 2px;}
  .twitter svg  { background:#fff; color:$dark-gray; border-radius:15px; width:15px; text-indent:2px; font-size:13px; height:15px; line-height:17px; margin-right:5px; padding: 2px;}
  @include grid-media($medium-screen-up) {
    text-align:center;
    nav { text-align:left; display:inline-block; width:49%; vertical-align:bottom; }
    nav:nth-child(2n) { margin-right:0; }
    .contact { padding-top:50px; color:$gray; }
  }
  @include grid-media($large-screen-up) {
    .wrapper {
      @include grid-column(8);
      .contact,
      nav { @include grid-column(4); }
    }
    .nav { @include grid-column(2); }
  }
}
