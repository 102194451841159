.header {
  $header: &;
  @include light-on-dark;
  color: #fff;

  background-size: cover;
  background-image: url('/assets/tile-back.png');
  overflow: hidden;

  position: relative;
  margin-bottom: 1.5em;
  min-height: 64px;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .carousel {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
    }
  }

  &__wrap {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0px, rgba(0, 0, 0, 0) 150px);
  }

  &__content {
    @extend .container-wide;
    position: relative;
    padding-bottom: 5vw;
    min-height: 120px;
    max-height: 50vh;
    z-index: 2;
  }

  &__bar {
    @include clearfix;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 20px 20px;
    text-align: left;
    transition: all 0.5s linear;
    height: 64px;

    &.open,
    &.scrolled-past {
      background: #fff;
      color: black;

      .main-nav__toggle {
        color: #0099bf;
      }

      .main-nav__items {
        color: white;
      }

      .header__logo--color {
        display: block;
      }

      .header__logo--white {
        display: none;
      }
    }

    &.scrolled-past {
      box-shadow: 0 2px 6px #000;
    }

    @include grid-media($large-screen-up) {
      padding: 50px 0px;
      position: static;
      height: auto;

      &.open,
      &.scrolled-past {
        background: transparent;
        color: inherit;

        .main-nav__toggle {
          color: #0099bf;
        }

        .main-nav__items {
          color: white;
        }

        .header__logo--color {
          display: none;
        }

        .header__logo--white {
          display: block;
        }
      }

      &.scrolled-past {
        box-shadow: none;
      }
    }
  }

  &__logo {
    display: block;
    float: left;

    img {
      width: 130px;
    }

    &--color {
      display: none;
    }

    &--white {
      display: block;
    }

    @include grid-media($large-screen-up) {
      width: 25%;
      height: 0;
      padding-bottom: 6.65%;

      img {
        width: 100%;
      }
    }
  }

  &__title {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

    @include grid-media($medium-screen-up) {
      font-size: 26px;
    }

    @include grid-media($large-screen-up) {
      font-size: 32px;
    }

    @include grid-media($xl-screen-up) {
      font-size: 40px;
    }
  }

  &--md {
    #{$header}__content {
      height: 40vw; // match aspect ratio of landing page carousel
    }
  }
}
