.modal {
  position: absolute;
  top: 25%;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.25);
  padding: 20px;
  left: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}