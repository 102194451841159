form.narrow {
  @include grid-column(4);
  button { width:100%; }
}

.select2-container { min-width:300px; }

select {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 3;
  transition: border-color;
  width: 100%;
  &:hover {
    border-color: darken($base-border-color, 10%);
  }
  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}


.selectize-dropdown,
.selectize-input,
.selectize-input input {
  font-size: $base-font-size;
}
