.table {
  $table-border-color: $base-border-color;
  $table-border: 1px solid $table-border-color;
  $table-background: lighten($table-border-color, 12%);
  $table-header-background: lighten($table-background, 10%);
  $table-hover-background: darken($table-background, 5%);
  $table-stripe-background: darken($table-background, 2%);
  $table-stripe-background-hover: darken($table-stripe-background, 5%);
  $table-padding: 0.75em 1em;

  border: $table-border;
  border-collapse: separate;
  border-left: 0;
  border-radius: $base-border-radius;
  border-spacing: 0;
  width: 100%;

  tbody {
    background-color: $table-background;

    tr:hover > td, tr:hover > th {
      background-color: $table-hover-background;
    }
    tr:nth-child(even) {
      background-color: $table-stripe-background;
      &:hover > td {
        background-color: $table-stripe-background-hover;
      }
    }
  }

  thead:first-of-type {
    tr:first-child > th:first-child {
      border-top-left-radius: $base-border-radius;
    }

    tr:first-child > th:last-child {
      border-top-right-radius: $base-border-radius;
    }
  }

  tbody:last-child {
    tr:last-child > td:first-child {
      border-bottom-left-radius: $base-border-radius;
    }

    tr:last-child > td:last-child {
      border-bottom-right-radius: $base-border-radius;
    }
  }

  thead {
    th {
      background-color: $table-header-background;
      border-bottom: 0;
      border-left: 1px solid $table-border-color;
      padding: $table-padding;
      text-align: left;

      &:first-child {
        border-left: 0;
      }
    }
  }

  tbody {
    background-color: $table-background;

    td {
      border-bottom: 0;
      border-left: 1px solid $table-border-color;
      border-top: 1px solid $table-border-color;
      padding: $table-padding;

      &:first-child {
        border-left: 0;
      }

      button {
        display: inline-block;
        font-size: 0.7em;
        line-height: $base-line-height;
        margin-bottom: 0.3em;
        margin-right: 0.5em;
        outline: none;
        padding: 0.3em 1em;
        width: 100%;

        @include grid-media($medium-screen-up) {
          margin-bottom: 0;
          width: auto;
        }
      }
    }
  }
}
