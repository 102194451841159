.table-minimal {
  $base-border-color: gainsboro !default;
  $base-border-radius: 3px !default;
  $base-background-color: transparent;
  $base-line-height: 1.5em !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $base-link-color: $action-color !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;
  $medium-screen: em(640) !default;
  $large-screen: em(860) !default;
  $base-font-color: $dark-gray !default;
  $table-border-color: $base-border-color;
  $table-border: 1px solid $table-border-color;
  $table-background: $base-background-color;
  $table-header-background: $base-background-color;
  $table-hover-background: darken($table-background, 2);
  $table-stripe-background: darken($table-background, 4);
  $table-stripe-background-hover: darken($table-stripe-background, 5);
  $table-padding: .75em 1em;

  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  tbody {
    tr:hover > td, tr:hover > th {
      background-color: $table-hover-background;
    }
    tr:first-child td {
      border-top: 2px solid $table-border-color;
    }
  }

  thead {
    th {
      background-color: $table-header-background;
      border-bottom: 0px;
      padding: $table-padding;
      text-align: left;
    }
  }

  tbody {
    background-color: $table-background;

    td {
      border-bottom: 0px;
      border-top: 1px solid $table-border-color;
      line-height: $base-line-height;
      padding: $table-padding;

      .btn {
        display: inline-block;
        font-size: .7em;
        line-height: $base-line-height;
        margin-bottom: .3em;
        margin-right: 0.5em;
        outline: none;
        padding: .3em 1em;
        width: auto;

        @include grid-media($medium-screen-up) {
          margin-bottom: 0;
          width: auto;
        }
      }
    }
  }

  &--wrap-mobile {
    @media screen and (max-width: $medium-screen - 1) {
      th {
        display: none;
      }
      td {
        display: block;
        border: none !important;
      }
      tr {
        display: block;
      }
      tr:not(:first-child) {
        border-top: $table-border;
      }
    }
  }
}

tr {
  cursor: auto;
}
