.form-container {
  &__before {
    max-width: 840px;
    margin: auto;
  }
  &__inner {
    @extend .container;
    max-width: 840px;
    border: 1px solid $light-gray;
    margin-bottom: 2em;
    padding: 1em $gutter-width;
  }
}