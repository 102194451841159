.initial {

  &__box {
    border: 2px dashed $light-blue;
    height: 75px;
    width: 100px;
    cursor: pointer;
    position: relative;
    overflow: auto;
    padding: 0;
    background: transparent;
    color: inherit;
    font-weight: normal;
    line-height: inherit;
    &:hover, &:focus {
      background-color: $lighter-gray !important;
      color: inherit;
      box-shadow: none;
      outline: none;
    }
  }

  &--incomplete &__box {
    background-color: map-get($alerts, 'alert');

    &:before {
      content: 'Initial';
      font-style: italic;
      text-align: center;
      display: block;
      @include center-vertically;
      left: 0;
      width: 100%;
    }
  }

  &--read-only &__box {
    cursor: default;
    background-color: $lighter-gray;
  }

  &__display {
    @extend .signature__display;
    @include center-vertically;
    left: 0;
    width: 100%;
    font-size: 25px;
    height: 30px;
    text-align: center;
  }

  @at-root .input.has-error & ~ .errors {
    text-align: left;
    margin-top: 5px;
  }
}
